import React from 'react';
import FormLayout from '../../components/formlayout'

const onboarding = () => {
    return <FormLayout>
        <div className="container mx-auto px-48 ">
            <div className="my-6 pb-4 border-b border-gray-200"><h3>Client Onboarding</h3></div>
            {/* basic information  */}
            <div className="grid grid-cols-5 mb-8">
                <div className="col-span-2">
                    <p className="text-gray-900 font-medium mb-2">Basic Information</p>
                    <p className='text-gray-500 text-xs leading-relaxed'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, eos. </p>
                </div>
                <div className="col-span-3 ml-4">
                    <div className="flex flex-row w-full space-x-2">
                        <div className="basis-1/2">
                            <p className="text-sm text-gray-600 font-medium mb-1">First Name</p>
                            <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-sm text-gray-600 font-medium mb-1">Last Name</p>
                            <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                        </div>
                    </div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Date of Birth</p>
                    <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Social Security Number</p>
                    <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Driver License</p>
                    <div className="flex flex-row w-full space-x-2">
                        <div className="basis-1/4 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">State</div>
                        <div className="basis-1/2 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">DL Number</div>
                        <div className="basis-1/4 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">Expiration Date</div>
                    </div>
                </div>
            </div>
            {/* spouse information  */}
            <div className="grid grid-cols-5 mb-8">
                <div className="col-span-2">
                    <p className="text-gray-900 font-medium mb-2">Spouse Information</p>
                    <p className='text-gray-500 text-xs leading-relaxed'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa veritatis sapiente cum harum rem vel dicta, accusantium, iste est nobis ratione odit. </p>
                    {/* toggle  */}
                    <div class="flex mt-4 w-full mb-12">

                        <label for="toggleB" class="flex items-center cursor-pointer">
                            <div class="relative">
                                <input type="checkbox" id="toggleB" class="sr-only" />
                                <div class="block bg-gray-300 w-14 h-8 rounded-full"></div>
                                <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                            </div>
                            <div class="ml-3 text-gray-500 text-xs font-normal">
                                Include Spouse Information
                            </div>
                        </label>

                    </div>
                </div>
                <div className="col-span-3 ml-4">
                    <div className="flex flex-row w-full space-x-2">
                        <div className="basis-1/2">
                            <p className="text-sm text-gray-600 font-medium mb-1">First Name</p>
                            <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-sm text-gray-600 font-medium mb-1">Last Name</p>
                            <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                        </div>
                    </div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Date of Birth</p>
                    <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Social Security Number</p>
                    <div className="w-full h-8 bg-white border border-gray-200 rounded mb-3"></div>
                    <p className="text-sm text-gray-600 font-medium mb-1">Driver License</p>
                    <div className="flex flex-row w-full space-x-2">
                        <div className="basis-1/4 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">State</div>
                        <div className="basis-1/2 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">DL Number</div>
                        <div className="basis-1/4 h-8 bg-white border border-gray-200 rounded mb-3 text-gray-400 text-xs pl-2 flex flex-col justify-center ">Expiration Date</div>
                    </div>
                </div>
            </div>

            <div className="h-96"></div>

        </div>
    </FormLayout>;
};

export default onboarding;
