import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import FormNav from "./forms/FormNav"
import {
  LockClosedIcon
} from '@heroicons/react/24/solid'


const FormLayout = ({ children }) => {

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <div id="outer" className="flex flex-col min-h-screen">
        <div id="inner" className="flex-grow bg-gray-50">
          <div className="w-full py-2 flex justify-center bg-gray-900"><LockClosedIcon className="text-gray-300 h-6 w-6 pr-2 align-middle" /><p className="text-gray-300">Roseman Wealth Secure Client Portal</p></div>
          <FormNav />

          <main>{children}</main>

        </div>
        <footer className="">
          <div id="footer-bottom" className="bg-gray-900">
            <div className="container mx-auto">

              <div className="text-xs text-gray-500 py-6">Securities offered through Kestra Investment Services, LLC (Kestra IS), Member FINRA/SIPC. Investment Advisory Services offered through Kestra Advisory Services, LLC (Kestra AS), an affiliate of Kestra IS. Kestra IS and Kestra AS are not affiliated with Roseman Wealth Advisors. This site is published for residents of the United States only. Registered Representatives of Kestra Investment Services, LLC and Investment Advisor Representatives of Kestra Advisory Services, LLC, may only conduct business with residents of the states and jurisdictions in which they are properly registered. Therefore, a response to a request for information may be delayed. Not all of the products and services referenced on this site are available in every state and through every representative or advisor listed. Neither Kestra IS or Kestra AS provides legal or tax advice and are not Certified Public Accounting Firms. For additional information, please contact our Compliance department at 844-553-7872. This communication is strictly intended for individuals residing in the state(s) of TX. No offers may be made or accepted from any resident outside the specific states referenced. <a className="text-current" href="https://bit.ly/KF-Disclosures" target="_blank">Investor Disclosures.</a> Check the background of this investment professional on <a className='text-current' href="http://brokercheck.finra.org/" target="_blank">FINRA’s BrokerCheck.</a></div>
              <div className="text-xs grid justify-items-center text-center text-gray-300 pb-6 flex flex-nowrap">
                Copyright {new Date().getFullYear()} Roseman Wealth Advisors.

                {/* <img src={plg} alt="plan. live. give." className="py-3" width="20%" /> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

// FormLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default FormLayout
