import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logos/rwa-logo.png';


const FormNav = () => {
    return <div className='bg-white py-6 flex justify-center'>
        <Link to="/">
            <span className="sr-only">Roseman Wealth Advisors</span>
            <img
                className='h-8 w-auto sm:h-10'
                src={logo}
                alt="roseman wealth advisors logo"
                style={{ filter: 'hue-rotate(30deg) saturate(300%' }}
            />
        </Link>
    </div>;
};

export default FormNav;
